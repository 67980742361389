import './App.css';
import routes from './routes';
import { Route,Routes } from 'react-router-dom';
import Detail from './pages/Career/Detail';

function App() {
  return (
   <Routes>
    {
      routes.map((route,index) =>{
        console.log(route)
        return(
          <Route key={index} path={route.path} element={route.component}/>
        )
      })
    }
   </Routes>
  );
}

export default App;
