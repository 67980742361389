import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import style from "./NavBar.module.css"

function NavBar() {
    const [flag,setFlag] = useState(false)
    const [nav,setNav] = useState(false)
    const navigate = useNavigate()
    useEffect(() =>{
        const nav = document.querySelector("nav")
        window.addEventListener("scroll",() =>{
            if(window.pageYOffset > 50){
                nav.classList.add(style.enhanceNavBar)
            }else{
                nav.classList.remove(style.enhanceNavBar)
            }
        })
    },[])

    const mobileNavToggle = (e) =>{
        setFlag(!flag)
    }

    const navBarHandler = (e) =>{
        setNav(!nav)
    }

  return (
    <>
        <nav>
            <div className={style.navbar}>
                <div className={style.logo} onClick = {(e) => navigate("/")}>
                    <img src='/images/logo.png' alt='logo'/>
                </div>
                <div className={style.responsive}>
                    <div className={style.navContent}>
                        <ul>
                            <li>
                                <button className={style.explore}>
                                    Explore
                                </button>
                                <div className={style.megaContent}>
                                    <div className={style.upSubContent}>
                                        <div className={style.subNavContent}>
                                            <div className={style.leftSection}>
                                                <div className={style.cont}>
                                                    <h1 className={style.name}>Scriptbox</h1>
                                                    <p>Experience a level of our quality in both design & customization works.</p>
                                                    <button onClick={(e) => navigate("/digital-marketing")} className={style.dMarketing}>
                                                        Digital Marketing
                                                    </button>
                                                </div>
                                            </div>
                                            <div className={style.rightSection}>
                                                <div className={style.classic}>
                                                    <span>CLASSIC</span>
                                                    <ul className={style.navLink}>
                                                        <li><Link to="/">Home</Link></li>
                                                        <li><Link to="/services">Services</Link></li>
                                                        <li><Link to="/about-us">About Us</Link></li>
                                                        <li><Link to="">Articles</Link></li>
                                                    </ul>
                                                </div>
                                                <div className={style.letsConnect}>
                                                    <span>LETS CONNECT</span>
                                                    <ul style={{marginBottom:"0.7rem"}} className={style.navLink}>
                                                        <li><Link to="/contact-us">Contact Us</Link></li>
                                                    </ul>
                                                    <span>OTHERS</span>
                                                    <ul className={style.navLink}>
                                                        <li><Link to="/career">Career <span>we're Hiring</span></Link></li>
                                                        <li><Link to="/hire-us">Hire Us</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <button onClick={(e) => navigate("/digital-marketing")} className={style.dMarketing}>
                                    Digital Marketing
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={style.mobileNav}>
                    <div className={style.bar} onClick={(e) => mobileNavToggle(e)}>
                        <i className="fa-solid fa-bars"></i>
                    </div>
                </div>
            </div>
            <div style={{display:"none"}} className={`${flag && style.visible} ${nav && style.fullHeight} ${style.enhanceNavBar}` }>
                <div className={`${style.content} ${nav && style.flow}`}>
                    <ul>
                        <li>
                            <button className={style.explore} onClick={navBarHandler}>
                                Explore
                            </button>
                            <div style={{display:"none"}} className={`${nav && style.subVisible}` }>
                                <div className={style.rightSection}>
                                    <div className={style.classic}>
                                        <span>CLASSIC</span>
                                        <ul className={style.navLink}>
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/services">Services</Link></li>
                                            <li><Link to="/about-us">About Us</Link></li>
                                            <li><Link to="">Articles</Link></li>
                                        </ul>
                                    </div>
                                    <div className={style.letsConnect}>
                                        <span>LETS CONNECT</span>
                                        <ul style={{marginBottom:"0.7rem"}} className={style.navLink}>
                                            <li><Link to="/contact-us">Contact Us</Link></li>
                                        </ul>
                                        <span>OTHERS</span>
                                        <ul className={style.navLink}>
                                            <li><Link to="/career">Career <span>we're Hiring</span></Link></li>
                                            <li><Link to="/hire-us">Hire Us</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <button onClick={(e) => navigate("/digital-marketing")} className={style.dMarketing}>Digital Marketing</button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </>
  )
}

export default NavBar