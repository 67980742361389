import React from 'react'
import style from "./AboutUs.module.css"
import NavBar from "../../PartialComponents/NavBar"
import Footer from "../../PartialComponents/Footer"
import Container from "../../components/Container"

function AboutUs() {
  return (
    <>
    <NavBar />
        <Container>
            <div className={style.aboutUsContainer}>
                <div className={style.aboutUsWrapper}>
                    <div className={style.aboutUsHeader}>
                        <h1 className={style.aboutUsHeading}>About Us</h1>
                        <p className={style.aboutUsDescription} >We are a group of professional consultants that provide fantastic solutions for you,
                            whether you are considered as an SME, non-profit or a consultant agency. We have over ten years of
                            experience in development, implementation, management and maintenance of CRM platforms and digital
                            marketing services for our valued clients and stakeholders.</p>
                    </div>
                    <div className={style.whatWeDo}>
                        <h1>What we do ?</h1>
                    </div>
                    <div className={style.salesForceImp}>
                        <div className={style.leftPart}>
                            <h2>Salesforce Marketing Implementation</h2>
                        </div>
                        <div className={style.rightPart}>
                            <p>We are subject matter certified experts in implementing Marketing cloud,we provide end to end implementation of marketing cloud.</p>

                            <p>Flexibility is the key! That’s why we use Salesforce to ensure an invaluable implementatio of
                                this extremely powerful primary application in the market. Power and flexibility of Salesforce
                                demands a deep comprehension of functionalities of the product to accurately adapt these features to
                                your business needs. This is where experienced Salesforce Consultants are invaluable in today's
                                market.</p>
                        </div>
		            </div>
                    <div className={style.digitalMarketing}>
                        <div className={style.leftPart}>
                            <h2>Digital Marketing</h2>
                        </div>
                        <div className={style.rightPart}>
                            <p>We provide end-to-end result driven user-friendly web design.</p>

                            <p>Our email marketing solutions provide the highest possible open and click-through rates for your
                                business which brings about the Highest ROI for your business.</p>

                            <p>We are team-playing self-starters committed to deliver unique results.</p>
                        </div>
                    </div>
			    </div>
            </div>
        </Container>
    <Footer />
    </>
  )
}

export default AboutUs