import React from 'react'
import style from "./Card.module.css"



function Card({heading,img,desc}) {
  return (
    <div className={style.cardContainer}>
        <div className={style.card}>
            <h1 className={style.cardHeading}>{heading}</h1>
            <img className={style.cardImg} src={img} alt="img"/>
            <p className={style.description}>{desc}</p>
        </div>
    </div>
  )
}

export default Card