import React from 'react'
import Container from '../../components/Container'
import Footer from '../../PartialComponents/Footer'
import NavBar from '../../PartialComponents/NavBar'
import style from "./Career.module.css"
import { Link, useNavigate } from 'react-router-dom'
function Career() {

  const navigate = useNavigate()

  const COMPANY_VALUES = [
    {
      head:"Hit heavy, stay small",
      desc:"Tight-knit, dynamic teams work with more agility, communication, and freedom than large-scale companies."
    },
    {
      head:"Ambition by the boatload",
      desc:"We love people who aim for greatness. They inspire and excite their teammates, raising the bar for all of us."
    },
    {
      head:"Open communication",
      desc:"We're big fans of transparency for many reasons, but the abridged version is: it makes easier."
    },
    {
      head:"Autonomy and attitude",
      desc:"We're a team of self-starters who take serious pride in our work – and it shows."
    },
    {
      head:"Support and win",
      desc:"When we empower others to succeed, we all win. (And we're not talking about participation trophies.)"
    },
    {
      head:"Teamwork makes the dream work",
      desc:"We work together to bring our passions and expertise to make Teachable the best it can be."
    }
  ]

  const POSITIONS = [
    {
      name:"Office Administrator",
      desc:"Canada, Full Time",
    },
    {
      name:"Business system Analyst",
      desc:"Canada, Full Time",
    },
    {
      name:"Database Administrator",
      desc:"Canada, Full Time",
    },
    {
      name:"Software Developer Programmer",
      desc:"Canada, Full Time",
    },
    {
      name:"Network Administrator",
      desc:"Canada, Full Time",
    },
    {
      name:"Senior Salesforce Developer",
      desc:"Canada, Full Time",
    },
    {
      name:"Salesforce Marketing Cloud Developer",
      desc:"Canada, Full Time",
    },
    {
      name:"Salesforce Marketing Cloud Email Developer",
      desc:"Canada, Full Time",
    },
    {
      name:"Senior Web Developer",
      desc:"Canada, Full Time",
    },
  ]
  return (
    <>
      <NavBar />
      <Container>
        <div className={style.careerContainer}>
            <div className={style.workWithUs}>
                <h1>Work with us</h1>
                <p>Work hard with highly motivated team of talented people and great teammates to launch perfectly crafted products you'll love.</p>
                <a href="#position">See Job Openings</a>
            </div>
            <div className={style.gallery}>
              <div className={style.galleryTop}>
                <div>
                  <img src={"./images/img1.jpg"} alt="img" />
                </div>
                <div>
                  <img src={"./images/img14.jpg"} alt="img" />
                </div>
              </div>
              <div className={style.galleryBottom}>
                <div>
                  <img src={"./images/img13.jpg"} alt="img" />
                </div>
                <div>
                  <img src={"./images/img2.jpg"} alt="img" />
                </div>
              </div>
            </div>
            <div className={style.companyValues}>
              <div className={style.header}>
                <p>COMPANY VALUES</p>
                <h1>The ScriptBox culture</h1>
              </div>
              <div className={style.cont}>
                {
                  COMPANY_VALUES.map((item,index) =>(
                    <div className={style.contBox} key={index}>
                      <span>—</span>
                      <div className={style.box}>
                        <h2>{item.head}</h2>
                        <p>{item.desc}</p>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
            <div id="position" className={style.openPosition}>
              <div className={style.header}>
                <p>OPEN POSITIONS</p>
                <h1>Current job openings</h1>
              </div>
              <div className={style.positionCont}>
                {
                  POSITIONS.map((item,index) => (

                    <div key={index} className={style.knowMore}>
                      <div>
                        <p>{item.name}</p> 
                      </div>
                      <div>
                        <p>{item.desc}</p> 
                      </div>
                      <div>
                        <p>
                          <Link to={"/career/" + item.name}>Know More</Link> 
                        </p>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
            <div className={style.looking}>
              <p>Are you Student? looking for Internship?</p>
            </div>
            <div className={style.apply}>
              <button className={style.btn}>Apply with us</button>
            </div>
        </div>
      </Container>
      <Footer />  
    </>
  )
}

export default Career