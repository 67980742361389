import React from 'react'
import style from "./NewsLetter.module.css"
function NewsLetter() {
  return (
    <div className={style.newsLetter}>
        <div className={style.heading}>
            <h1>Newsletter</h1>
        </div>
        <div className={style.subscribe}>
            <p>Subscribe to my newsletter and stay tuned to<br /> the latest updates and articles!</p>
        </div>
        <div className={style.form}>
            <input type="email" className={style.email} placeholder="Enter Your Email Address"/>
            <button className={style.signup} >Sign up</button>
        </div>
    </div>
  )
}

export default NewsLetter