import React from 'react'

function PageNotFound() {
  return (
    <div 
      style={{
        height:"99vh",
      }}>
      <img 
        style={{
          width:"100%",
          height:"100%",
          objectFit:"contain"
        }} src='/images/error-404.svg' alt='404'/>
    </div>
  )
}

export default PageNotFound