import Home from "./pages/Home/Home"
import ContactUs from "./pages/Contact/ContactUs"
import PageNotFound from "./pages/PageNotFound/PageNotFound"
import AboutUs from "./pages/AboutUs/AboutUs"
import Services from "./pages/Services/Services"
import HireUs from "./pages/HireUs/HireUs"
import Career from "./pages/Career/Career"
import Detail from "./pages/Career/Detail"
import DigitalMarketing from "./pages/DMarketting/DigitalMarketing"

const routes = [
    {
        path:"/",
        component:<Home />
    },
    {
        path:"/contact-us",
        component:<ContactUs />
    },
    {
        path:"/about-us",
        component:<AboutUs />
    },
    {
        path:"/services",
        component:<Services />
    },
    {
        path:"/hire-us",
        component:<HireUs />
    },
    {
        path:"/career",
        component:<Career />
    },
    {
        path:"/career/:data",
        component:<Detail />
    },
    {
        path:"/digital-marketing",
        component:<DigitalMarketing />
    },
    {
        path:"*",
        component:<PageNotFound />
    },
] 

export default routes