import React,{useEffect,useState} from 'react'
import NavBar from '../../PartialComponents/NavBar'
import Footer from '../../PartialComponents/Footer'
import Container from '../../components/Container'
import style from "./DigitalMarketing.module.css"
import Typewriter from 'typewriter-effect';
import SalesForce from '../../components/SalesForce'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
function DigitalMarketing() {

    const [data,setData] = useState({
        name:"",
        email:"",
      })
    
      const [enable,setEnable] = useState(false)
      const [flag,setFlag] = useState(false)
      const [message,setMessage] = useState({code:-1,msg:""})
    
      useEffect(() =>{
        if(!data.email || !data.name){
          setEnable(false)
          return
        }
        setEnable(true)
      },[data])
    
      const submitForm = async (e) =>{
        e.preventDefault()
    
        const form = new FormData()
        form.append("name",data.name)
        form.append("email",data.email)
        form.append("page",'Digital Marketing')
        setFlag(true)
        let config = {
          method: 'post',  
          maxBodyLength: Infinity,
          url: 'https://tifinapi.tiffinhub.ca/tiffin_api/scriptbox_contact_form',
          headers: { 
            'Content-Type' : 'multipart/form-data'
          },
          data : form
        };
        try{
          const result = await axios(config)
          if(result.data.code === 1){
            setFlag(false)
            setData({
              name:"",
              email:"",
              company:"",
              message:""
            })
            setMessage({
              code:result.data.code,
              msg:result.data.message
            })
          }else{
            setMessage({
              code:result.data.code,
              msg:result.data.message + " We are working on it"
            })
            setFlag(false)
          }
        }catch(e) {
          setFlag(false)
          setMessage({
            code:0,
            msg:"Network Problem We are working on it"
          })
          console.log(e)
        }
      }

    const navigate = useNavigate()
    const DATA = [
        {
            name:"Sms Marketing",
            description:"SMS marketing can is an effective tool, allowing you to reach customers and potential customers in real-time with targeted and effective messaging.",
            url:"./images/icon-71.svg"
        },
        {
            name:"Lead collection",
            description:"Lead generation falls into the broad category of your business's inbound marketing strategy.",
            url:"./images/icon-71.svg"
        },
        {
            name:"Email Marketing:",
            description:"Email marketing help you to build relationship with your audience while also driving traffic to your blog, social media and website.",
            url:"./images/icon-71.svg"
        },
        {
            name:"Marketing strategies",
            description:"Automation strategy helps companies better understand their customers and predict customer behavior.",
            url:"./images/icon-71.svg"
        },
    ]

  return (
    <>
    <NavBar />
    <Container>
      <div className={style.digitalContainer}>
        <div className={style.hero}>
            <div className={style.message}>
                <h1>Ready to grow your business?</h1>
            </div>
            <div className={style.animation}>
                <h1>
                    <Typewriter
                        options={{
                        strings: ['We know when to target', 'We know how to target',"We know where to target"],
                        autoStart: true,
                        loop: true,
                        delay:40,
                    }}
                    />
                </h1>
            </div>
            <div className={style.getInTouchBtn}>
                <button>Get In Touch</button>
            </div>
        </div>
        <div className={style.different}>
            <div className={style.leftPart}>
                <h1>Strategies that makes the difference</h1>
                <p>After brainstorming about insights, get the power to create something real. Bring your ideas to life and share your vision with concrete elements. Make collaboration and communication easier with your team or your client.</p>
                <p>Use our tools to explore your ideas and make your vision come true. Then share your work easily.</p>
                <div className={style.startNow}>
                    <button onClick={(e) => navigate("/hire-us")}>Start Now</button>
                </div>
            </div>
            <div className={style.rightPart}>
                <div className={style.img}>
                    <img src='https://images.pexels.com/photos/4308098/pexels-photo-4308098.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' alt='img' />
                </div>
            </div>
        </div>
        <div className={style.salesforce}>
        {
            DATA.map(item => (
                <SalesForce 
                    img={item.url}
                    heading={item.name}
                    description={item.description}
                />
            ))
        }
        </div>
        <div className={style.letsConnect}>
            <h1>Let's Connect</h1>
            <div className={style.content}>
                <div className={style.left}>
                    <h2>Let us explain how we can help you grow your business.</h2>
                    <p>“ Whatever your goal - we will get your there . ”</p>
                </div>
                <div className={style.right}>
                    <div className={style.formPart}>
                        <h3>Fill out the form and we'll be in touch as soon as possible.</h3>
                        <div className={style.formPartWrapper}>
                        <div>
                            <div className={style.inputField}>
                                <input value={data.name} type="text" onChange={(e) => setData({
                                ...data, 
                                name:e.target.value,
                            })} required/>
                                <label>Name</label>
                            </div>
                        </div>
                        <div>
                            <div className={style.inputField}>
                                    <input type="email" value={data.email} onChange={(e) => setData({
                                    ...data, 
                                    email:e.target.value,
                                })}required/>
                                <label>Email</label>
                            </div>
                        </div>
                        <div className={style.privacy}>
                            <p>Yes, I'd like to receive occasional marketing emails from Front. I have the right to opt out at any time. <span>View privacy policy</span>.</p>
                        </div>
                        <div className={style.workingTogetherBtn}>
                            <button disabled={enable ? false : true} onClick={(e) => submitForm(e)} className={`${style.btn} ${enable ? '' : style.disable}`}>
                            {flag ? "Processing" : "Submit"}
                        </button>
                            <p>We'll get back to you in 1-2 business days.</p>
                        </div>
                        <div className={style.messageResponse}>
                            {
                            message.code === -1 ? null :<>
                                {
                                message.code === 1 ? <>
                                    <p>{message.msg}</p>
                                </> : <>
                                    <p style={{color:"red"}}>{message.msg}</p>
                                </>
                                }
                            </> 
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </Container>
    <Footer/>
  </>
  )
}

export default DigitalMarketing