import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import Footer from '../../PartialComponents/Footer'
import NavBar from '../../PartialComponents/NavBar'
import Container from '../../components/Container'
import style from "./Details.module.css"
import axios from 'axios'

function Detail() {
    const {data} = useParams()

    const DETAILS = {
        "Business system Analyst" : {
          description:"Our office is seeking a full-time Office Assistant with strong communication and organizational skills to join our team located in Mississauga.The candidate must be people-oriented as we strive to provide the best services. The candidate must have some technical knowledge.",
          responsibility : [
            "One or more of the CNAMEs you provided are already associated with a different resource.",
            "Ensuring the showroom is immaculately presented at all times.",
            "Ordering stock from overseas suppliers and coordinating large shipments.",
            "Data entry in XERO, generating supplier invoices and applying payments.",
            "Generate monthly sales reports and weekly stock reports."
          ],
          requirements : [
            "Exceptional organisational skills, strong attention to detail and ability to multitask.",
            "Intermediate technical skills, XERO experience is advantageous but not essential.",
            "Professional presentation and excellent communication skills.",
            "Reliable with a proactive and enthusiastic attitude.",
            "A strong team player who has the ability to work independently as well as in a team."
          ],
          experience : [
            "3+ years experience in office administration.",
            "Type and proofread correspondence, forms and other documents.",
            "Schedule and confirm appointments.",
            "Greet people and direct them to contacts or service areas.",
            "Determine and establish office procedures and routines."
          ]
        },
        "Office Administrator" : {
          description:"Our office is seeking a full-time Office Assistant with strong communication and organizational skills to join our team located in Mississauga.The candidate must be people-oriented as we strive to provide the best services. The candidate must have some technical knowledge.",
          responsibility : [
            "One or more of the CNAMEs you provided are already associated with a different resource.",
            "Ensuring the showroom is immaculately presented at all times.",
            "Ordering stock from overseas suppliers and coordinating large shipments.",
            "Data entry in XERO, generating supplier invoices and applying payments.",
            "Generate monthly sales reports and weekly stock reports."
          ],
          requirements : [
            "Exceptional organisational skills, strong attention to detail and ability to multitask.",
            "Intermediate technical skills, XERO experience is advantageous but not essential.",
            "Professional presentation and excellent communication skills.",
            "Reliable with a proactive and enthusiastic attitude.",
            "A strong team player who has the ability to work independently as well as in a team."
          ],
          experience : [
            "3+ years experience in office administration.",
            "Type and proofread correspondence, forms and other documents.",
            "Schedule and confirm appointments.",
            "Greet people and direct them to contacts or service areas.",
            "Determine and establish office procedures and routines."
          ]
        },
        "Database Administrator" : {
          description : "Our office is seeking a full-time Office Assistant with strong communication and organizational skills to join our team located in Mississauga.The candidate must be people-oriented as we strive to provide the best services. The candidate must have some technical knowledge.",
          responsibility : [
            "One or more of the CNAMEs you provided are already associated with a different resource.",
            "Ensuring the showroom is immaculately presented at all times.",
            "Ordering stock from overseas suppliers and coordinating large shipments.",
            "Data entry in XERO, generating supplier invoices and applying payments.",
            "Generate monthly sales reports and weekly stock reports."
          ],
          requirements : [
            "Exceptional organisational skills, strong attention to detail and ability to multitask.",
            "Intermediate technical skills, XERO experience is advantageous but not essential.",
            "Professional presentation and excellent communication skills.",
            "Reliable with a proactive and enthusiastic attitude.",
            "A strong team player who has the ability to work independently as well as in a team."
          ],
          experience : [
            "3+ years experience in office administration.",
            "Type and proofread correspondence, forms and other documents.",
            "Schedule and confirm appointments.",
            "Greet people and direct them to contacts or service areas.",
            "Determine and establish office procedures and routines."
          ]
        },
        "Software Developer Programmer" : {
          description:"We are looking for a Web Developer who will be responsible for understanding business requirements and delivering high-quality technical solutions that empower the Marketing",
          responsibility : [
            "Design and implement web application improvements to our current websites",
            "Develop new features for in-house back-office Web Application",
            "Maintain Front-end and Back-end components of our ecommerce website",
            "Prioritize internal development activities along with urgent, often unscheduled, development requests with a high level of urgency",
            "Willingness to learn and expand skills"
          ],
          requirements : [
            "Write clear, maintainable code",
            "Be vocal and transparent if concerns arise regarding deliverable timelines",
            "Maintain project confidentiality and protect operations by keeping information confidential",
            "Build and maintain core engine components, pipelines and tools on multiple hardware platforms.",
            "Full Software Lifecycle application development – designing, coding, debugging and documenting applications"
          ],
          experience : [
            "2+ years of relevant experience in a similar role, as a web-based game developer",
            "Knowledge of software engineering best practices, including coding standards, code reviews, build processes, and testing",
            "Experience managing a team of developers",
            "Deep knowledge of web front-end development, including Typescript, Canvas, WebGL",
            "Some understanding of backend web development"
          ]
        },
        "Network Administrator" : {
          description:"Our office is seeking a full-time Office Assistant with strong communication and organizational skills to join our team located in Mississauga.The candidate must be people-oriented as we strive to provide the best services. The candidate must have some technical knowledge.",
          responsibility:[
            "General reception duties and ad hoc office administration",
            "Ensuring the showroom is immaculately presented at all times",
            "Assisting with sales tasks such as creating quotes, processing orders and invoices, and taking payments",
            "Generate monthly sales reports and weekly stock reports",
            "Attend, facilitate and provided minutes and agenda for management meetings"
          ],
          requirements:[
            "Exceptional organisational skills, strong attention to detail and ability to multitask",
            "Intermediate technical skills, XERO experience is advantageous but not essential-",
            "Professional presentation and excellent communication skills",
            "Reliable with a proactive and enthusiastic attitude",
            "A strong team player who has the ability to work independently as well as in a team"
          ],
          experience:[
            "3+ years experience in office administration.",
            "Schedule and confirm appointments",
            "Greet people and direct them to contacts or service areas",
            "Determine and establish office procedures and routines",
            "Answer electronic enquiries"
          ]

        },
        "Senior Salesforce Developer" : {
          description:"The successful candidate will be accountable for helping develop solutions that ensure we continue to deliver exceptional tools for the business to service our customers accurately and efficiently. Key to this position is the ability to provide technical leadership on the latest development, testing and deployment technologies.",
          responsibility:[
            "Manage the scope and technical delivery of projects.",
            "Coordinate development efforts with the team.",
            "Be responsible for the quality of deliverables.",
            "Participate in peer code reviews.",
            "Assume technical leadership role on some projects."
          ],
          requirements : [
            "Excellent written and oral communication skills to confirm requirements and explore potential solutions",
            "Excellent analytical and process-oriented skills with an eye on re-use",
            "Ability to manage multiple projects with tight deadlines",
            "Strong critical thinking skills to recognize assumptions, evaluate arguments, draw conclusions, and proactively propose solutions",
            "Confident in your knowledge and open to developing trusting relationships and provide guidance to support the development of colleagues (team player)"
          ],
          experience : [
            "Salesforce Administrative Tools",
            "Sales Cloud and Service Cloud",
            "HTML 5, CSS, JQuery, JSON / XML",
            "VisualForce, APEX Coding (Class & Trigger), Salesforce Lightning Components",
            "Salesforce Workflow Rules and Approvals, Visual Flow, Process Builder"
          ]
        },
        "Salesforce Marketing Cloud Developer" : {
          description : "We are looking for a Salesforce Marketing Cloud Developer who will be responsible for understanding business requirements and delivering high-quality technical solutions that empower the Marketing teams to utilize the Marketing Cloud automation platform.",
          responsibility : [
            "Responsible for design & technical implementation",
            "Teach and coach on how to best use SFMC",
            "Responsible for iteratively improving our Marketing Cloud data model and architecture",
            "Stay in tune with the latest Salesforce technology updates, changes, community, and roadmap(s)",
            "Collaborate with the internal feature development team to determine the best path for Salesforce platform implementation"
          ],
          requirements : [
            "Certified in Salesforce Marketing Cloud Developer and/or Marketing Cloud Consultant is required",
            "Bachelor degree in Computer science, engineering or related field (preferred)",
            "3+ years of salesforce development and implementation experience",
            "Deep knowledge of the salesforce features",
            "Knowledge of Salesforce Marketing Cloud (SFMC) aka ExactTarget is required"
          ],
          experience : [
            "Salesforce development and implementation: 3 years (Preferred)"
          ]
        },
        "Salesforce Marketing Cloud Email Developer" : {
          description:"We are currently seeking a Digital Messaging Specialist to manage all aspects of email marketing and digital messaging campaigns. The primary focus is on Digital Messaging campaign deployment including reporting, data management, and campaign optimization. The Digital Messaging Specialist will have the opportunity to work with our passionate team of Solutions Consultants, Developers and Campaign Managers.",
          responsibility : [
            "Manage the execution of ongoing email and digital messaging campaigns for clients through various marketing automation technologies.",
            "Update email campaigns to client brand guidelines and templates.",
            "Work in HTML to assist building dynamic elements and troubleshooting",
            "Build, test, and send custom digital messaging programs.",
            "Launch live Email and Digital Messaging campaigns."
          ],
          requirements : [
            "At least 1 year of experience with enterprise Email Deployment platforms (Salesforce Marketing Cloud, Adobe, Oracle, Silverpop)",
            "Proven understanding of data manipulation, including working in MS Access and MS Excel. SQL experience is a plus.",
            "Proven ability to detect and troubleshoot technical issues and bugs.",
            "Strong grasp of email marketing metrics, segmentation, sender-score, deliverability, reputation, etc.",
            "College Diploma or University Degree"
          ],
          experience : [
            "Email marketing: 1 years (Required)",
            "HTML and CSS: 2 years (Preferred)",
            "SQL: 1 years (Preferred)",
            "SFMC or Adobe Campaign: 1 years (Required)"
          ]
        },
        "Senior Web Developer" : {
          description:"We are looking for a Web Developer who will be responsible for understanding business requirements and delivering high-quality technical solutions that empower the Marketing",
          responsibility : [
            "Design and implement web application improvements to our current websites",
            "Develop new features for in-house back-office Web Application",
            "Maintain Front-end and Back-end components of our ecommerce website",
            "Prioritize internal development activities along with urgent, often unscheduled, development requests with a high level of urgency",
            "Willingness to learn and expand skills"
          ],
          requirements : [
            "Write clear, maintainable code",
            "Be vocal and transparent if concerns arise regarding deliverable timelines",
            "Maintain project confidentiality and protect operations by keeping information confidential",
            "Build and maintain core engine components, pipelines and tools on multiple hardware platforms.",
            "Full Software Lifecycle application development – designing, coding, debugging and documenting applications"
          ],
          experience : [
            "2+ years of relevant experience in a similar role, as a web-based game developer",
            "Knowledge of software engineering best practices, including coding standards, code reviews, build processes, and testing",
            "Experience managing a team of developers",
            "Deep knowledge of web front-end development, including Typescript, Canvas, WebGL",
            "Some understanding of backend web development"
          ]
        }
      }

      const [formData,setFormData] = useState({
        name:"",
        email:"",
        message:""
      })

      const [enable,setEnable] = useState(false)
      const [flag,setFlag] = useState(false)
      const [message,setMessage] = useState({code:-1,msg:""})

      useEffect(() =>{
        if(!formData.email || !formData.name || !formData.message){
          setEnable(false)
          return
        }
        setEnable(true)
      },[formData])
      console.log(formData);

      const submitForm = async (e) =>{
        e.preventDefault()
    
        const form = new FormData()
        form.append("name",formData.name)
        form.append("message",formData.message)
        form.append("email",formData.email)
        form.append("page",'Career')
        setFlag(true)

        let config = {
          method: 'post',  
          maxBodyLength: Infinity,
          url: 'https://tifinapi.tiffinhub.ca/tiffin_api/scriptbox_contact_form',
          headers: { 
            'Content-Type' : 'multipart/form-data'
          },
          data : form
        };
        try{
          const result = await axios(config)
          if(result.data.code === 1){
            setFlag(false)
            setFormData({
              name:"",
              email:"",
              company:"",
              message:""
            })
            setMessage({
                code:result.data.code,
                msg:result.data.message
              })
          }else{
            setMessage({
                code:result.data.code,
                msg:result.data.message + " We are working on it"
              })
            setFlag(false)
          }
        }catch(e) {
          setFlag(false)
          setMessage({
            code:0,
            msg:"Network Problem We are working on it"
          })
          console.log(e)
        }
      }

  return (
    <>
      <NavBar />
      <Container>
        <div className={style.detailContainer}>
          <div className={style.left}>
            <div className={style.fullTime}>
              <p>CANADA - FULL-TIME</p>
            </div>
            <div className={style.name}>
              <h1>{data}</h1>
            </div>
            <div className={style.desc}>
              <p>{
                DETAILS[data].description
              }</p>
            </div>
            <div className={style.responsibility}> 
              <h4>Responsibility : </h4>
                <ul>
                  {
                    DETAILS[data].responsibility.map((item,index) =>(
                      <li key={index}>{item}</li>
                    ))
                  }
                </ul>
            </div>
            <div className={style.requirements}> 
                <h4>Requirements : </h4>
                <ul>
                  {
                    DETAILS[data].requirements.map((item,index) =>(
                      <li key={index}>{item}</li>
                    ))
                  }
                </ul>
            </div>
            <div className={style.experience}>
                <h4>Experience : </h4>
                <ul>
                  {
                    DETAILS[data].requirements.map((item,index) =>(
                      <li key={index}>{item}</li>
                    ))
                  }
                </ul>
            </div>
            <div className={style.covid}>
                  <h4>COVID-19 precaution(s):</h4>
                  <ul>
                    <li>Remote interview process</li>
                    <li>Virtual meetings</li>
                  </ul>
            </div>
          </div>
          <div className={style.right}>
              <div className={style.formPart}>
                    <div className={style.formPartWrapper}>
                    <div>
                        <div className={style.inputField}>
                        <input value={formData.name} type="text" onChange={(e) => setFormData({
                                ...formData, 
                                name:e.target.value,
                            })} required/>
                            <label>Name</label>
                        </div>
                    </div>
                    <div>
                        <div className={style.inputField}>
                          <input type="email" value={formData.email} onChange={(e) => setFormData({
                                ...formData, 
                                email:e.target.value,
                            })}required/>
                            <label>Email</label>
                        </div>
                    </div>
                    <div className={style.file}>
                        <div>
                            <label>Attached CV</label>
                            <input type="file" name="file"/>
                        </div>
                    </div>
                    <div>
                        <div className={style.inputField}>
                        <textarea value={formData.message} required onChange={(e) => setFormData({
                            ...formData, 
                            message:e.target.value,
                          })}></textarea>
                        <label>Type your message</label>
                        </div>
                    </div>
                    <div className={style.workingTogetherBtn}>
                        <button disabled={enable ? false : true} onClick={(e) => submitForm(e)} className={`${style.btn} ${enable ? '' : style.disable}`}>
                            {flag ? "Processing" : "Apply Now"}
                        </button>
                        <p>We'll get back to you in 1-2 business days.</p>
                    </div>
                    <div className={style.messageResponse}>
                    {
                      message.code === -1 ? null :<>
                        {
                          message.code === 1 ? <>
                            <p>{message.msg}</p>
                          </> : <>
                            <p style={{color:"red"}}>{message.msg}</p>
                          </>
                        }
                      </> 
                    }
                    </div>
                    </div>
              </div>
            </div>
        </div>
      </Container>
      <Footer />     
    </>
  )
}

export default Detail