import React,{useEffect,useState} from 'react'
import Container from '../../components/Container'
import NavBar from '../../PartialComponents/NavBar'
import Footer from '../../PartialComponents/Footer'
import style from "./HireUs.module.css"
import axios from 'axios'
function HireUs() {

    const [data,setData] = useState({
        name:"",
        email:"",
        company:"",
        message:""
      })
    
      const [enable,setEnable] = useState(false)
      const [flag,setFlag] = useState(false)
      const [message,setMessage] = useState({code:-1,msg:""})
    
      useEffect(() =>{
        if(!data.email || !data.name || !data.company || !data.message){
          setEnable(false)
          return
        }
        setEnable(true)
      },[data])
    
      const submitForm = async (e) =>{
        e.preventDefault()
    
        const form = new FormData()
        form.append("name",data.name)
        form.append("message",data.message)
        form.append("email",data.email)
        form.append("company",data.company)
        form.append("page",'Hire Us')
        setFlag(true)
        let config = {
          method: 'post',  
          maxBodyLength: Infinity,
          url: 'https://tifinapi.tiffinhub.ca/tiffin_api/scriptbox_contact_form',
          headers: { 
            'Content-Type' : 'multipart/form-data'
          },
          data : form
        };
        try{
          const result = await axios(config)
          if(result.data.code === 1){
            setFlag(false)
            setData({
              name:"",
              email:"",
              company:"",
              message:""
            })
            setMessage({
                code:result.data.code,
                msg:result.data.message
              })
          }else{
            setMessage({
                code:result.data.code,
                msg:result.data.message + " We are working on it"
              })
            setFlag(false)
          }
        }catch(e) {
          setFlag(false)
          setMessage({
            code:0,
            msg:"Network Problem We are working on it"
          })
          console.log(e)
        }
      }

  return (
    <>
        <NavBar />
        <Container>
            <div className={style.hireUsSection}>
                <div className={style.readyToStart}>
                    <div className={style.readyToStartContainer}>
                        <div className={style.readyToStartContent}>
                            <h1>READY TO START WORKING TOGETHER ON YOUR SOLUTIONS?</h1>
                            <h2>GIVE US FEW DETAILS.</h2>
                        </div>
                    </div>
                </div>
                <div className={style.formPart}>
                    <div className={style.formPartWrapper}>
                    <div>
                        <div className={style.inputField}>
                            <input value={data.name} type="text" onChange={(e) => setData({
                                ...data, 
                                name:e.target.value,
                            })} required/>
                            <label>Name</label>
                        </div>
                    </div>
                    <div>
                        <div className={style.inputField}>
                            <input type="email" value={data.email} onChange={(e) => setData({
                                ...data, 
                                email:e.target.value,
                            })}required/>
                            <label>Email</label>
                        </div>
                    </div>
                    <div>
                        <div className={style.inputField}>
                            <input value={data.company} type="text" onChange={(e) => setData({
                            ...data, 
                            company:e.target.value,
                            })} required/>
                            <label>Company</label>
                        </div>
                    </div>
                    <div>
                        <div className={style.inputField}>
                        <textarea value={data.message} required onChange={(e) => setData({
                            ...data, 
                            message:e.target.value,
                          })}></textarea>
                        <label>Type your message</label>
                        </div>
                    </div>
                    <div className={style.workingTogetherBtn}>
                        <button disabled={enable ? false : true} onClick={(e) => submitForm(e)} className={`${style.btn} ${enable ? '' : style.disable}`}>
                            {flag ? "Processing" : "Let's Start Working Together"}
                        </button>
                        <p>We'll get back to you in 1-2 business days.</p>
                    </div>
                    <div className={style.messageResponse}>
                    {
                      message.code === -1 ? null :<>
                        {
                          message.code === 1 ? <>
                            <p>{message.msg}</p>
                          </> : <>
                            <p style={{color:"red"}}>{message.msg}</p>
                          </>
                        }
                      </> 
                    }
                    </div>
                    </div>
                </div>
            </div>
        </Container>
        <Footer/>
    </>
  )
}

export default HireUs