import React from 'react'
import style from "./SalesForce.module.css"
function SalesForce({img,heading,description}) {
  return (
    <div className={style.salesForceWrapper}>
        <div className={style.left_sec}>
            <div className={style.imgWrapper}>
              <img src={img} alt="img" />
            </div>
        </div>
        <div className={style.right_sec}>
          <h2>{heading}</h2>
          <p>{description}</p>
        </div>
    </div>
  )
}

export default SalesForce