import React, { useState } from 'react'
import Container from '../../components/Container'
import Footer from '../../PartialComponents/Footer'
import NavBar from '../../PartialComponents/NavBar'
import SalesForce from "../../components/SalesForce"
import style from "./Services.module.css"
import CardAndImage from '../../components/CardAndImage'
import { Link } from 'react-router-dom'
function Services() {


    const [content,setContent] = useState([
        {
            img:"./images/icon-18.svg",
            heading:"Salesforce Implementation",
            description:"Helping businesses with salesforce platform implementation"
        },
        {
            img:"./images/icon-27.svg",
            heading:"Salesforce Consultation",
            description:"Scriptbox provides consultation for your sales, marketing and customer service needs"
        },
        {
            img:"./images/icon-28.svg",
            heading:"Salesforce Customization",
            description:"Scriptbox help you customize your Salesforce solution to address your business challenges."
        },
        {
            img:"./images/icon-45.svg",
            heading:"Salesforce Development",
            description:"Building reliable applications that make Salesforce fully cover our customers’ or their clients’ business needs."
        },
        {
            img:"./images/icon-19.svg",
            heading:"Salesforce Integration",
            description:"We can both develop custom integration modules and tune integration apps from AppExchange."
        },
        {
            img:"./images/icon-7.svg",
            heading:"Salesforce Marketing Cloud",
            description:"Salesforce Marketing Cloud is marketing automation software, which helps businesses improve the efficiency of their marketing activities"
        },
        {
            img:"./images/icon-69.svg",
            heading:"Salesforce Upgrades",
            description:"Scriptbox provides custom add-ons and technical advancement to your salesforce in order to stay ahead of the competition."
        },
        {
            img:"./images/icon-2.svg",
            heading:"Salesforce Service Cloud",
            description:"Scriptbox provides customer service softwares that make customer support quick and effecient."
        },
        {
            img:"./images/icon-70.svg",
            heading:"Salesforce Sales Cloud",
            description:"Primary goal of Sales Cloud is to assist sales managers and automate the sales process."
        },
        {
            img:"./images/icon-71.svg",
            heading:"Salesforce Integration",
            description:"We can both develop custom integration modules and tune integration apps from AppExchange."
        },
        {
            img:"./images/icon-19.svg",
            heading:"Salesforce Case Studies",
            description:"We can both develop custom integration modules and tune integration apps from AppExchange."
        },
        {
            img:"./images/icon-4.svg",
            heading:"Salesforce Support",
            description:"Scriptbox's team of experienced developers and administrators are ready to keep your Salesforce solution up, stable and ensures its fast processing."
        },
    ])

    const [list,setList] = useState([
        {
            color:"#377dff",
            img:"./images/img15.png",
            bullet_list :[
                "Email Studio",
                "Automation studio",
                "Journey builder",
                "Content builder",
                "Integration",
                "Dataroma"
            ],
            heading:"Salesforce Marketing Cloud"
        },
        {
            color:"#f5ca99",
            img:"./images/img12.png",
            bullet_list :[
                "Highlighting sales reps’ strong and weak points",
                "Influence reps' productivity",
                "Allows sales managers to improve their reps’ performance",
                "Advanced sales forecasting",
                "Smarter sales decisions",
                "Helps to map out the sales strategy"
            ],
            heading:"Reports and dashboards"
        }
    ])
  return (
    <>
        <NavBar />
        <Container>
            <div className={style.servicesWrapper}>
                <div className={style.serviceHeading}>
                    <h1>script<span>box</span> provides services in salesforce marketing cloud</h1>
                </div>
                <div className={style.heroPart}>
                    <div className={style.leftHeroImg}>
                        <div className={style.leftImg}>
                            <img alt='img' src='./images/img6.jpg' />
                        </div>
                    </div>
                    <div className={style.rightHeroImg}>
                        <div className={style.rightImg}>
                            <img alt='img' src='/images/img5.jpg' />
                        </div>
                    </div>
                </div>
                <div className={style.professionalService}>
                    <span>ScriptBox Professional Services</span>
                    <h2>
                        We are growing with the new projects on Salesforce platform to help companies reach bigger audience.
                    </h2>
                </div>
                <div className={style.professionalServiceContent}>
                    {
                        content.map(item => (
                            <SalesForce 
                                img={item.img}
                                heading={item.heading}
                                description={item.description}
                            />
                        ))
                    }
                </div>
                <div className={style.cardAndImagePart}>
                    {
                        list.map(ele => (
                            <CardAndImage 
                                color = {ele.color}
                                bullet_list = {ele.bullet_list}
                                img = {ele.img}
                                heading = {ele.heading}
                            />
                        ))
                    }
                </div>
                <div className={style.marketingCloud}>
                    <Link to="">
                        <img width="50%" src="https://upload.wikimedia.org/wikipedia/commons/1/1b/Sf-marketingcloud-logo.png" alt="" srcset="" />
                    </Link>
                </div>
            </div>
        </Container>
        <Footer />
    </>
  )
}

export default Services