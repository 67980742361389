import React from 'react'
import style from"./CircleCard.module.css"
function CircleCard({img, branDName, brandDescription, services, size,url}) {
    const style1 = {
        transform:'scale(1.4)'
    }
  return (
    <div className={style.circleCardContainer}>
        <div className={style.circleCardImg}>
            <div className={style.CircleCardImgContainer}>
                <div className={style.CircleCardImgWrapper}>
                    {
                        size ? 
                        <img style={style1} alt='img' src={img}/>:
                        <img alt='img' src={img}/>
                    }
                </div>
            </div>
        </div>
        <div className={style.brandName}> 
            <h4>{branDName}</h4>
            <p>{brandDescription}</p>
        </div>
        <div className={style.brandDesc}>
            <div className={style.brandDescWrapper}>
                <h4> <a style={{textDecoration:"none",color:"#1190d8"}} href={url} target="_blank" ><b>{services}</b></a> </h4>
            </div>
        </div>
    </div>
  )
}

export default CircleCard