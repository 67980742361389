import React, { useEffect, useState } from 'react'
import Card from '../../components/Card'
import CircleCard from '../../components/CircleCard'
import Container from '../../components/Container'
import NewsLetter from '../../components/NewsLetter'
import Footer from '../../PartialComponents/Footer'
import NavBar from '../../PartialComponents/NavBar'
import style from "./Home.module.css"
import Typewriter from 'typewriter-effect';
import axios from 'axios'

function Home() {
  const [data,setData] = useState({
    name:"",
    email:"",
    company:"",
    message:""
  })

  const [enable,setEnable] = useState(false)
  const [flag,setFlag] = useState(false)
  const [message,setMessage] = useState({code:-1,msg:""})

  useEffect(() =>{
    if(!data.email || !data.name || !data.company || !data.message){
      setEnable(false)
      return
    }
    setEnable(true)
  },[data])

  const submitForm = async (e) =>{
    e.preventDefault()

    const form = new FormData()
    form.append("name",data.name)
    form.append("message",data.message)
    form.append("email",data.email)
    form.append("company",data.company)
    form.append("page",'Home')
    setFlag(true)
    let config = {
      method: 'post',  
      maxBodyLength: Infinity,
      url: 'https://tifinapi.tiffinhub.ca/tiffin_api/scriptbox_contact_form',
      headers: { 
        'Content-Type' : 'multipart/form-data'
      },
      data : form
    };
    try{
      const result = await axios(config)
      if(result.data.code === 1){
        setFlag(false)
        setData({
          name:"",
          email:"",
          company:"",
          message:""
        })
        setMessage({
          code:result.data.code,
          msg:result.data.message
        })
      }else{
        setMessage({
          code:result.data.code,
          msg:result.data.message + " We are working on it"
        })
        setFlag(false)
      }
    }catch(e) {
      setFlag(false)
      setMessage({
        code:0,
        msg:"Network Problem We are working on it"
      })
      console.log(e)
    }
  }

  const [cardList] = useState([
    {
      heading:"Email Studio",
      img:"./images/Email Studio.png",
      desc:"Build personalized email campaigns with the world’s #1 email marketing platform."
    },
    {
      heading:"Journey Builder",
      img:"./images/Journey Builder.png",
      desc:"Build 1-to-1 customer journeys across all channels and departments."
    },
    {
      heading:"Mobile Studio",
      img:"./images/Mobile Studio.png",
      desc:"Personalize mobile interactions including SMS, push notifications, and chat messaging."
    },
    {
      heading:"Marketing Cloud Customer Data",
      img:"./images/Marketing Cloud Customer Data Platform.png",
      desc:"Unify, segment, activate, and analyse all your data with a customer data platform."
    },
    {
      heading:"Marketing Cloud Intelligence",
      img:"./images/Marketing Cloud Intelligence.png",
      desc:"Use one platform for all your marketing data, investments, KPIs, and decisions."
    },
    {
      heading:"Marketing Account Engagement",
      img:"./images/Marketing Cloud Account Engagement powered by Pardot.png",
      desc:"Unite marketing and sales on a single platform for B2B marketing automation."
    },
    {
      heading:"Marketing Cloud Advertising",
      img:"./images/Marketing Cloud Advertising.png",
      desc:"Target 1-to-1 advertising using your CRM data to acquire and re-engage customers."
    },
    {
      heading:"Content Management",
      img:"./images/Content Management.png",
      desc:"Create and deliver amazing content experiences fast — all from one platform."
    },
    {
      heading:"Marketing Personalization",
      img:"./images/Marketing Cloud Personalisation.png",
      desc:"Deliver real-time, 1-to-1 customer experiences at every touchpoint."
    },
])

const [circleCardList] = useState([
  {
    img:"./images/tiffinhublogo.svg",
    brandName:"Tiffinhub",
    brandDescription:"Tiffinhub Services and G1",
    services:"Tiffinhub Services",
    url:"https://tiffinhub.ca",
    size:false
  },
  {
    img:"./images/logo_green.svg",
    brandName:"Foodflyer",
    brandDescription:"Foodflyer Services and Restaurant",
    services:"Foodflyer Services",
    url:"https://merchant.foodflyer.ca",
    size:true
  },
  // {
  //   img:"./images/StackImmigration_Logo.png",
  //   brandName:"Immigration",
  //   brandDescription:"Stack Immigration services",
  //   services:"Immigration Services"
  // },
])

  return (
    <>
      <NavBar />
       <Container> 
          <div className={style.section}>
            <div className={style.message}>
              <h2 className={style.animation}>
                <Typewriter
                    options={{
                    strings: ['ScriptBox','Karamjeet Singh'],
                    autoStart: true,
                    loop: true,
                    delay:40,
                }} />
              </h2>
              <h1>Turn your ideas into a success!</h1>
                <p className={style.lead}>
                  <span style={{fontWeight: "bold",color: "black"}}>script</span>
                  <span style={{color: "#0098da"}}>box</span>&nbsp;
                  help you create the best possible solution.
                </p>
            </div>
            <div className={style.clippath}>
                <svg class="figure-image-overlay-svg" viewBox="0 0 1137.5 979.2" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
                  <path d="M561.5,957.4c81.1-7.4,155.5-49.3,202.4-115.7C840,739.8,857,570,510.7,348.3C-35.5-1.5-4.2,340.3,2.7,389
                  c0.7,4.7,1.2,9.5,1.7,14.2l29.3,321c14,154.2,150.6,267.8,304.9,253.8L565.5,957.4z" fill="#F9FBFF"></path>
                  <defs>
                      <path d="M1137.5,0H450.4l-278,279.7C22.4,430.6,24.3,675,176.8,823.5l0,0C316.9,960,537.7,968.7,688.2,843.6l449.3-373.4V0z" id="mainHeroSVG1" fill='#fff'></path>                  
                  </defs>
                  <clipPath id="mainHeroSVG2">
                    <use href="#mainHeroSVG1"></use>
                  </clipPath>
                  <g clip-path="url(#mainHeroSVG2)" transform="matrix(1 0 0 1 0 0)">
                      <image height="750" transform="matrix(1.4462 0 0 1.4448 52.8755 0)" width="750" href="./images/img2.jpg"></image>
                  </g>
                </svg>
            </div>
          </div>
            <div className={style.saleForce}>
              <h1>Salesforce Marketing cloud implementation</h1>
              <div className={style.cardHolder}>
                {
                  cardList.map((item,index) =>{
                    return(
                      <Card
                      key={index}
                      heading = {item.heading}
                      img = {item.img}
                      desc = {item.desc}
                      />
                    )
                  })
                }
              </div>
            </div>
            <div className={style.customer}>
              <h1>Our Customer</h1>
                <div className={style.customerHolder}>
                  {
                    circleCardList.map((item,index) =>{
                      return(
                        <CircleCard
                          img = {item.img}
                          brandName = {item.brandName}
                          brandDescription = {item.brandDescription}
                          services = {item.services}
                          size = {item.size}
                          url = {item.url}
                          key={index}
                        />
                      )
                    })
                  }
                </div>
            </div>
            <div className={style.readyToStart}>
              <div className={style.readyToStartContainer}>
                  <div className={style.readyToStartContent}>
                    <h1>READY TO START WORKING TOGETHER ON YOUR SOLUTIONS?</h1>
                    <h2>GIVE US FEW DETAILS.</h2>
                  </div>
              </div>
            </div>
            <div className={style.formPart}>
                <div className={style.formPartWrapper}>
                  <div>
                      <div className={style.inputField}>
                          <input value={data.name} type="text" onChange={(e) => setData({
                            ...data, 
                            name:e.target.value,
                          })} required/>
                          <label>Name</label>
                      </div>
                  </div>
                  <div>
                      <div className={style.inputField}>
                          <input type="email" value={data.email} onChange={(e) => setData({
                            ...data, 
                            email:e.target.value,
                          })}required/>
                          <label>Email</label>
                      </div>
                  </div>
                  <div>
                      <div className={style.inputField}>
                          <input value={data.company} type="text" onChange={(e) => setData({
                            ...data, 
                            company:e.target.value,
                          })} required/>
                          <label>Company</label>
                      </div>
                  </div>
                  <div>
                    <div className={style.inputField}>
                      <textarea value={data.message} required onChange={(e) => setData({
                            ...data, 
                            message:e.target.value,
                          })}></textarea>
                      <label>Type your message</label>
                    </div>
                  </div>
                  <div className={style.workingTogetherBtn}>
                      <button disabled={enable ? false : true} onClick={(e) => submitForm(e)} className={`${style.btn} ${enable ? '' : style.disable}`}>
                          {flag ? "Processing" : "Let's Start Working Together"}
                      </button>
                      <p>We'll get back to you in 1-2 business days.</p>
                  </div>
                  <div className={style.messageResponse}>
                    {
                      message.code === -1 ? null :<>
                        {
                          message.code === 1 ? <>
                            <p>{message.msg}</p>
                          </> : <>
                            <p style={{color:"red"}}>{message.msg}</p>
                          </>
                        }
                      </> 
                    }
                  </div>
                </div>
            </div>
            <div className={style.newsLetter}>
                <NewsLetter />
            </div>
      </Container>
      <Footer />
    </>
  )
}

export default Home