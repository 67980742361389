import React from 'react'
import style from "./CardAndImage.module.css"

function CardAndImage({bullet_list,img,color,heading}) {
  return (
    <div style={{backgroundColor:color}} className={style.cardAndImageContainer}>
        <div className={style.head}>
            <h2>{heading}</h2>
        </div>
        <div className={style.cardAndImageWrapper}>
            <div className={style.uoList}>
                <ul>
                    {
                        bullet_list.map(ele => (
                            <li>{ele}</li>
                        ))
                    }
                </ul>
            </div>
            <div className={style.cardImage}>
                <div className={style.cardImageWrapper}>
                    <img src={img} alt='img' />
                </div>
            </div>
        </div>
    </div>
  )
}

export default CardAndImage