import React,{useEffect,useState} from 'react'
import Container from '../../components/Container'
import Footer from '../../PartialComponents/Footer'
import NavBar from '../../PartialComponents/NavBar'
import style from "./ContactUs.module.css"
import axios from 'axios'


function ContactUs() {

  const [data,setData] = useState({
    name:"",
    email:"",
    message:""
  })

  const [enable,setEnable] = useState(false)
  const [flag,setFlag] = useState(false)
  const [message,setMessage] = useState({code:-1,msg:""})

  useEffect(() =>{
    if(!data.email || !data.name || !data.message){
      setEnable(false)
      return
    }
    setEnable(true)
  },[data])

  const submitForm = async (e) =>{
    e.preventDefault()

    const form = new FormData()
    form.append("name",data.name)
    form.append("message",data.message)
    form.append("email",data.email)
    form.append("page",'Contact Us')

    setFlag(true)
    let config = {
      method: 'post',  
      maxBodyLength: Infinity,
      url: 'https://tifinapi.tiffinhub.ca/tiffin_api/scriptbox_contact_form',
      headers: { 
        'Content-Type' : 'multipart/form-data'
      },
      data : form
    };
    try{
      const result = await axios(config)
      if(result.data.code === 1){
        setFlag(false)
        setData({
          name:"",
          email:"",
          message:""
        })
        setMessage({
          code:result.data.code,
          msg:result.data.message
        })
      }else{
        setMessage({
          code:result.data.code,
          msg:result.data.message + " We are working on it"
        })
        setFlag(false)
      }
    }catch(e) {
      setFlag(false)
      setMessage({
        code:0,
        msg:"Network Problem We are working on it"
      })
      console.log(e)
    }
  }

  return (
    <>
      <NavBar />
      <Container>
        <div className={style.contactUsWrapper}>
          <div className={style.contactUsContainer}>
            <div className={style.parentAddress}>
              <div className={style.head}>
                <h1>Get in touch</h1>
                <p>We'd love to talk about how we can help you.</p>
              </div>
              <div className={style.address}>
                <div className={style.left}>
                  <div>
                    <h3>Call Us : </h3>
                    <p>+1 (647) 640-0701</p>
                  </div>
                  <div>
                    <h3>Address : </h3>
                    <p>2970 Drew Rd suite 203, Mississauga, ON L4T 0A6</p>
                  </div>
                </div>
                <div className={style.right}>
                  <div>
                    <h3>Email us : </h3>
                    <p>contact@scriptbox.ca</p>
                  </div>
                </div>
              </div>
              <div className={style.otherAddress}>
                <div className={style.detail}>
                  <h3>Other Address</h3>
                  <div className={style.left}>
                    <div>
                      <h3>Call Us : </h3>
                      <p>+91 90418 58068</p>
                    </div>
                    <div>
                      <h3>Address : </h3>
                      <p>RRIS, I/s, opp. Shoor Hospital, Khazana Gate, Amritsar, Punjab 143001, India</p>
                    </div>
                  </div>
                  <div className={style.right}>
                    <div>
                      <h3>Hours : </h3>
                      <p>Mon - Sat 10am - 9pm</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={style.contactForm}>
              <div className={style.formPart}>
                <div className={style.formPartWrapper}>
                  <div>
                      <div className={style.inputField}>
                          <input value={data.name} type="text" onChange={(e) => setData({
                            ...data, 
                            name:e.target.value,
                          })} required/>
                          <label>Name</label>
                      </div>
                  </div>
                  <div>
                      <div className={style.inputField}>
                          <input type="email" value={data.email} onChange={(e) => setData({
                            ...data, 
                            email:e.target.value,
                          })}required/>
                          <label>Email</label>
                      </div>
                  </div>
                  <div>
                    <div className={style.inputField}>
                      <textarea value={data.message} required onChange={(e) => setData({
                            ...data, 
                            message:e.target.value,
                          })}></textarea>
                      <label>Type your message</label>
                    </div>
                  </div>
                  <div className={style.workingTogetherBtn}>
                      <button disabled={enable ? false : true} onClick={(e) => submitForm(e)} className={`${style.btn} ${enable ? '' : style.disable}`}>
                          {flag ? "Processing" : "Apply Now"}
                      </button>
                      <p>We'll get back to you in 1-2 business days.</p>
                  </div>
                  <div className={style.messageResponse}>
                    {
                      message.code === -1 ? null :<>
                        {
                          message.code === 1 ? <>
                            <p>{message.msg}</p>
                          </> : <>
                            <p style={{color:"red"}}>{message.msg}</p>
                          </>
                        }
                      </> 
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer/>
    </>
  )
}

export default ContactUs