import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import style from "./Footer.module.css"

function Footer() {

    const navigate = useNavigate()

  return (
    <div className={style.footContainer}>
        <div className={style.footWrapper}>
            <div className={style.address}>
                <div className={style.footImg} onClick={(e) => navigate("/")}>
                    <img alt='logo' src='/images/Scriptboxlogowhitefont.png'/>
                </div>
                <div className={style.addressContent}>
                    <ul>
                        <li>
                            <span><i class="fa-solid fa-location-arrow"></i></span>
                            <span>Rexdale Blvd, Etobicoke, ON M9W 0B5</span>
                        </li>
                        <li>
                            <span><i class="fa-solid fa-phone"></i></span>
                            <span>+1 6476400701</span>
                        </li>
                        <li>
                            <span><i class="fa-solid fa-location-arrow"></i></span>
                            <span>SCO 132, opp.starbucks Market, Ranjit Avenue, Amritsar 143001</span>
                        </li>
                        <li>
                            <span><i class="fa-solid fa-phone"></i></span>
                            <span>+1 6476400701</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={style.rightSec}>
                <div className={style.company}>
                    <div className={style.cont}>
                        <span>Company</span>
                    </div>
                    <ul>
                        <li>
                            <Link to='/about-us'>
                                <span>About</span>
                            </Link>
                        </li>
                        <li>
                            <Link to='/career'>
                                <span>Career <span className={style.hiring}>we're Hiring</span></span>
                            </Link>
                        </li>
                        <li>
                            <Link to='/hire-us'>
                                <span>Hire Us</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className={style.resources}>
                    <div className={style.cont}>
                        <span>Resources</span>
                    </div>
                    <ul>
                        <li>
                            <Link to='/contact-us'>  
                                <span><i class="fa-solid fa-circle-info"></i></span>
                                <span>Help</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <hr className={style.lineBreak}></hr>
        <div className={style.copyRightSection}>
            <p>©{new Date().getFullYear()} Scriptbox. All rights reserved</p>
            <p>When you visit or interact with our sites, services or tools, we or our authorised service providers may use cookies</p>
            <p>for storing information to help provide you with a better, faster and safer experience and for marketing purposes.</p>
        </div>
    </div>
  )
}

export default Footer